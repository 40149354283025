import getStore from './store';
import jwtDecode from 'jwt-decode';
import { UserInfo } from './models';

const store = getStore();

export const backbone = {
  store,
  useIsEmbedded: () => useBackbone((state) => state.embedInfo !== undefined),
  isEmbed: () => store.getState().embedInfo !== undefined,
  update: store.setState,
  setAuthToken: (authToken: string) => {
    const user = jwtDecode<UserInfo>(authToken);

    store.setState((state) => ({
      ...state,
      authToken: authToken,
      user,
    }));

    return user;
  },
  getLanguageCode: () => store.getState().country.languageCode,
  getToken: () => store.getState().authToken,
};

export const useBackbone = store;

export * from './hookableRenders';
export * from './dialogOrchestrator';
export * from './locationHooks';
