import { DialogOrchestrator, DialogPrioity, HookableRender, useDialogContext } from '@visikon/backbone/src';
import { Button, Dialog } from '@visikon/spine/src';
import { ModalWrapper } from '@visikon/nativeui/src';
import { useDispatch } from 'react-redux';
import { resetSeenPrivacyPolicy } from '../../actions/userActions';

export const DEBUG_ROUTE = '/debug';
export function DebugScreen() {
  return (
    <div style={{ padding: '0 15px', overflow: 'auto' }}>
      <h1>Bug me not!</h1>
      <Button
        onClick={() => {
          console.log('queue');

          DialogOrchestrator.queue(
            {
              prioity: DialogPrioity.NORMAL,
              component: TestDialog,
              componentProps: { id: '#1' },
            },
            {
              prioity: DialogPrioity.HIGH,
              component: TestDialog,
              componentProps: { id: '#2' },
            },
          );
        }}
      >
        Toggle Test Dialog
      </Button>
      <PrivacyPolicyDebugOptions />
      <HookableRender identifier="screen/debug" />
    </div>
  );
}

function PrivacyPolicyDebugOptions() {
  const dispatch = useDispatch();

  const handler = () => {
    dispatch(resetSeenPrivacyPolicy());
  };

  return <Button onClick={handler}>Mark Privacy Policy Unseen</Button>;
}

function TestDialog({ id }: { id: string }) {
  const dialog = useDialogContext();

  const handleDialogClick = (event) => {
    // Stop the click event from propagating to ModalWrapper
    event.stopPropagation();
  };

  return (
    <ModalWrapper onClick={dialog.close}>
      <Dialog
        open
        header={'Header goes here ' + id}
        onClick={handleDialogClick}
        actions={
          <>
            <Button size="small" onClick={dialog.close}>
              Dialog button
            </Button>
            <Button size="small" variant="outline" onClick={dialog.close}>
              Another button
            </Button>
          </>
        }
      >
        <>
          Ved brug af Mit Forløb® samler vi oplysninger om dit besøg ved hjælp af egne cookies for at måle hvordan Mit Forløb® bliver brugt, så vi kan
          udvikle indhold og funktioner. Data du indtaster, bliver gemt anonymiseret. <a href="https://visikon.com">Læs vores datapolitik her</a>
        </>
      </Dialog>
    </ModalWrapper>
  );
}
