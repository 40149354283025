import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import React, { useEffect, useState } from 'react';

export type SlideDirection = 'up' | 'down';

export const SlideIn = styled.div<{ slideDirection: SlideDirection; transformation: boolean }>`
  position: absolute;
  opacity: 0;
  ${(props) => {
    if (props.transformation) {
      return `opacity: 1; animation: slide-in 1s forwards;`;
    }
  }}

  @keyframes slide-in {
    0% {
      transform: translateY(300%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    z-index: 100;
  }
`;

export const useSliderTransformation = (slideDirection: SlideDirection) => {
  const [transformation, setTransformation] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTransformation(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);
  return transformation;
};

interface IProps {
  children: React.ReactNode;
  slideDirection: SlideDirection;
}

export const Slider = ({ children, slideDirection }: IProps) => {
  const transformation = useSliderTransformation(slideDirection);
  return (
    <SlideIn slideDirection={slideDirection} transformation={transformation}>
      {children}
    </SlideIn>
  );
};
