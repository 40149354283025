import * as React from 'react';
import styled from 'styled-components';
import { I18N } from '../../internationalization/AppText';
import { PopupDialog } from '../../components/popup/PopupDialog';
import { DocumentRenderer } from '../../components/documentViewer/DocumentRenderer';
import { Translation } from '@visikon/core-models/languageTypes';
import { Content } from '@visikon/core-models';

const Title = styled.div``;

type Props = {
  onCancel: () => void;
  document: Translation<Content.Text>;
};

export const ContactDialog = ({ onCancel, document }: Props) => {
  const title = (
    <Title>
      <I18N text={'contact'} />
    </Title>
  );
  return (
    <PopupDialog
      title={title}
      onDismiss={onCancel}
      content={
        <div tabIndex={0}>
          <DocumentRenderer document={(document!.content as any).document} style={{ padding: 'unset' }} />
        </div>
      }
    />
  );
};
