import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { surveySubmitSuccess, alreadyAnswered, surveySubmit } from '../actions/surveyActions';
import { getCurrentProgram } from './resourcesSaga';
import { getIsReferencedUser } from './utils';
import { fetchPost } from '@visikon/utils/src/utils';

function* submitSurveySaga(action: Action<any>) {
  const programId = yield select(getCurrentProgram);
  const isReferencedUser = yield select(getIsReferencedUser);

  const data = { ...action.payload, programId, isReferencedUser };

  try {
    const result = yield call(fetchPost, 'survey/submit', {}, data);
    console.log('RESULT', result);
    if (result.msg === 'Ok') {
      yield put(surveySubmitSuccess());
    } else {
      if (result.msg === 'Already answered this survey') {
        yield put(alreadyAnswered(result.msg));
      }
    }
  } catch (e: any) {
    console.log("Couldn't save survey");
  }
  // try {
  //     console.log("ACTION", action.payload)
  //     yield call(fetchPost, "survey/submit", {}, action.payload)
  //     console.log("Submit is fetch")

  // } catch (e: any) {
  //     console.log("Couldn't save survey")

  // }
}

export function* surveySaga() {
  // Save data
  yield takeEvery(surveySubmit, submitSurveySaga);
}
