import * as React from 'react';
import { PopupDialog } from '../../components/popup/PopupDialog';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  min-width: 420px;
  @media (max-width: 420px) {
    min-width: 100%;
  }
`;

const ContactBody = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
`;
const ContactActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type Props = {
  onDismiss?: () => void;
};

export const VISIKON_PHONE_NR = '+45 31 47 77 48';
export const VISIKON_SUPPORT_EMAIL = 'support@visikon.com';

export const ContactPopup = (props: Props) => {
  const content = (
    <Container>
      <ContactBody>
        <I18N text={'contact_popup_body'} />
      </ContactBody>
      <ContactActions>
        <div>
          <a href={'tel:' + VISIKON_PHONE_NR.replace(/\s/g, '')}>
            <I18N text={'contact_popup_phone'} replace={{ $PHONE_NO: VISIKON_PHONE_NR }} />
          </a>
        </div>
        <div>
          <a href={'mailto:' + VISIKON_SUPPORT_EMAIL}>{VISIKON_SUPPORT_EMAIL}</a>
        </div>
      </ContactActions>
    </Container>
  );

  const translatedTitle = i18N('contact_popup_title');

  return <PopupDialog onDismiss={props.onDismiss} slideDirection={'up'} title={translatedTitle} content={content} />;
};
