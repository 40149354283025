import * as React from 'react';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { ProgramInfo } from '../../common/listPrograms';
import { PopupDialog } from '../../components/popup/PopupDialog';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import { i18n } from '../../internationalization/i18n';

const RestoreBody = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
`;

type Props = {
  program: ProgramInfo;
  onConfirm: () => void;
  onCancel: () => void;
};

export const RestoreProgramDialog = ({ program, onConfirm, onCancel }: Props) => {
  return (
    <PopupDialog
      title={i18N('home_restore_program_title')}
      confirmAction={{ onClick: onConfirm, text: i18n('home_restore_program_accept'), style: { textTransform: 'none' } }}
      cancelAction={{ onClick: onCancel, text: i18n('home_restore_program_cancel'), style: { textTransform: 'none' } }}
      content={
        <RestoreBody>
          <I18N text={'home_restore_program_body'} replace={{ $PROGRAM_NAME: program.name }} />
        </RestoreBody>
      }
    />
  );
};
