import * as React from 'react';
import { ProgramCard } from './ProgramCard';
import { GetButton } from '../program/SmartBanner.styles';
import { I18N, SetDocumentTitle } from '../../internationalization/AppText';
import { PopupDialog } from '../../components/popup/PopupDialog';
import { useEffect, useState } from 'react';
import { i18n } from '../../internationalization/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { setShowArchivedPrograms } from '../../actions/resourcesActions';
import { SmartBanner } from '../program/SmartBanner';
import { Country } from '@visikon/core-models/i18n/languages';
import { mergeProgram, mergeProgramReset, setNavLink, unsetNavLink } from '../../actions/userActions';
import { CodeInputField } from '@visikon/spine';
import { LanguageSelectorModal } from '../../internationalization/LanguageSelectorModal';
import { usePersonalCodeField } from '../login/PersonalCodeField';
import { Spinner } from '../../common/Spinner';
import { CODE_INPUT_LENGTH, ERROR_TOO_FEW_CHARACTERS, ErrorMessage, MERGE_ERROR_NEW_LANGUAGE } from '../login/EndUserLogin';
import * as Styles from './HomeScreen.styles';
import { useProgramList } from '../program/useProgramList';
import { Programlist } from './ProgramList';
import { SetDocumentThemeColor } from '../../common/DocumentThemeColor';
import { Tokens } from '@visikon/spine/src/styles/tokens';

export const HOME_ROUTE = '/home';

export const HomeScreen = () => {
  const dispatch = useDispatch();
  const showArchived = useSelector<IState, boolean>((state) => !!state.resources.showArchivedPrograms);
  const mergeError = useSelector<IState, any>((state) => state.shareProgram.mergeResourcesError);
  const { programList } = useProgramList();

  const archivedPrograms = programList.filter((p) => p.archived).map((program, index) => <ProgramCard key={index} program={program} index={index} />);
  const hasArchivedPrograms = archivedPrograms.length > 0;

  const [mergeCode, setMergeCode] = useState('');
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [languageCodes, setLanguageCodes] = useState<string[]>([]);
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [showLanguageSelectModal, setShowLanguageSelectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { personalCodeLink } = usePersonalCodeField();

  useEffect(() => {
    dispatch(mergeProgramReset());
    dispatch(unsetNavLink());
  }, []);

  useEffect(() => {
    if (showArchived && !hasArchivedPrograms) {
      dispatch(setShowArchivedPrograms(false));
    }
  }, [showArchived, programList]);

  useEffect(() => {
    if (mergeError) {
      setLoading(false);
    }
    if (mergeError?.error === MERGE_ERROR_NEW_LANGUAGE) {
      setLanguageCodes(mergeError?.availableLanguages || []);
      dispatch(mergeProgramReset());
      setShowLanguageSelectModal(true);
    }
  }, [mergeError]);

  const handleSelectedCountryFromMergeCode = (country: Country | null) => {
    setShowLanguageSelectModal(false);
    setLoading(false);
    if (!country) {
      dispatch(mergeProgramReset());
    } else {
      // Will force the merge of the code
      dispatch(mergeProgram({ code: mergeCode, country }));
    }
  };

  const handleAddProgramClick = () => {
    if (mergeCode.length < CODE_INPUT_LENGTH) {
      setErrorCode(ERROR_TOO_FEW_CHARACTERS);
    }
    dispatch(mergeProgramReset());
    setLoading(true);
    // Will check if the program has a translation in active language
    setTimeout(() => dispatch(mergeProgram({ code: mergeCode })), 500);
  };

  const handleOnChange = (code: string) => {
    if (mergeError) {
      dispatch(mergeProgramReset());
    }
    setMergeCode(code);
  };

  const codeField = loading ? (
    <Styles.CodeFieldContainer>
      <Styles.SpinnerContainer>
        <Spinner />
      </Styles.SpinnerContainer>
    </Styles.CodeFieldContainer>
  ) : (
    <Styles.CodeFieldContainer>
      <Styles.PersonalCodeText>
        <I18N text={'home_add_new_program_body'} />
      </Styles.PersonalCodeText>
      <Styles.CodeContainer>
        <CodeInputField code={mergeCode} error={mergeError} onChange={handleOnChange} login={handleAddProgramClick} loading={loading} />

        <Styles.ErrorText>{ErrorMessage(errorCode ?? mergeError?.error)}</Styles.ErrorText>

        <GetButton onClick={handleAddProgramClick}>
          <I18N text="continue" />
        </GetButton>
      </Styles.CodeContainer>
      <Styles.PersonalCodeLink>{personalCodeLink}</Styles.PersonalCodeLink>
    </Styles.CodeFieldContainer>
  );

  return (
    <Styles.HomeScreenContainer>
      <SetDocumentTitle text={'home_your_programs'} />
      <SetDocumentThemeColor color={Tokens.color.homeBackground} />
      <SmartBanner />
      <Styles.Container>
        <Programlist setMergeCode={setMergeCode} setErrorCode={setErrorCode} setShowAddDialog={setShowAddDialog} />
      </Styles.Container>

      {showAddDialog ? (
        <PopupDialog slideDirection={'up'} title={i18n('home_add_new_program_title')} onDismiss={() => setShowAddDialog(false)} content={codeField} />
      ) : null}

      {showLanguageSelectModal ? <LanguageSelectorModal onSelectCountry={handleSelectedCountryFromMergeCode} languageCodes={languageCodes} /> : null}
    </Styles.HomeScreenContainer>
  );
};
