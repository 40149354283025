import * as React from 'react';
import { dialogActionsContainer, dialogBody, dialogContainer, dialogHeader, dialogHeaderText, dialogSection, dialogStyle } from './Dialog.styles';
import { Typography } from '../..';

interface DialogProps extends React.PropsWithChildren, React.HTMLAttributes<HTMLDialogElement> {
  // For some reason VSCODE doesn't see props from HTMLDialogElement like 'open' when importing our Dialog component
  open?: boolean;
  header?: React.ReactNode;
  actions: React.ReactNode;
}

function DialogHeader({ header }: Pick<DialogProps, 'header'>) {
  if (!header) return null;

  const isStringHeader = typeof header === 'string';
  const content = isStringHeader ? <Typography.H4 className={dialogHeaderText} children={header} /> : header;

  return <div className={dialogHeader}>{content}</div>;
}

export function Dialog({ open, header, children, actions, ...htmlDialogOptions }: DialogProps) {
  if (!open) return null;

  return (
    <dialog open={open} className={dialogStyle} {...htmlDialogOptions} style={{ overflow: 'hidden' }}>
      <div className={dialogContainer}>
        <DialogHeader header={header} />
        <div className={dialogBody} children={children} />
        {React.Children.count(actions) ? <div className={dialogActionsContainer} children={actions} /> : null}
      </div>
    </dialog>
  );
}
