import { PropsWithChildren } from 'react';
import { ScreenAutoTracker } from './analytics/ScreenAutoTracker';
import { Switch } from 'react-router-dom';
import { AppRoute } from './AppRoute';
import { LoginContainerV2 } from './screens/login/LoginContainer';
import { LogoutContainer } from './screens/login/LogoutContainer';
import { MainContent } from './components/MainContent';
import { useIsLoggedIn } from '@visikon/backbone/src/selectors';
import { AUTH_CODE_PATH, AuthRouter } from './screens/startcode/AuthRouter';
import { StaffLogin } from './screens/login/StaffLogin';
import { START_CODE_PATH, StartCodeRouter } from './screens/startcode/StartCodeRouter';
import { SET_USER_PATH, SetUserRouter } from './screens/login/SetUserRouter';
import { LocationHooks } from '@visikon/backbone';
import { TestRoutes } from './TestRoutes';
import { EMBED_PATH, EmbedRoute } from './screens/login/EmbedRoute';

export function AppRouter({ children }: PropsWithChildren) {
  const loggedIn = useIsLoggedIn();

  LocationHooks.useRecordPath();

  return (
    <ScreenAutoTracker>
      <Switch>
        {children}
        <AppRoute key="start" path={START_CODE_PATH} component={StartCodeRouter} />
        <AppRoute key="auth" path={AUTH_CODE_PATH} component={AuthRouter} />,
        <AppRoute key="logout" enabled={loggedIn} path={'/logout'} component={LogoutContainer} />
        <AppRoute key="set-user" path={SET_USER_PATH} component={SetUserRouter} />
        <AppRoute key="embed" path={EMBED_PATH} component={EmbedRoute} />
        {process.env.NODE_ENV !== 'production' && TestRoutes()}
        {loggedIn ? (
          <AppRoute key="main" path="*" component={MainContent} />
        ) : (
          <Switch>
            <AppRoute key="staff-login" path="/staff" component={StaffLogin} />
            <AppRoute key="login" path="*" component={LoginContainerV2} />
          </Switch>
        )}
      </Switch>
    </ScreenAutoTracker>
  );
}
