import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import {
  shareProgramSms,
  shareProgramSuccess,
  shareProgramFailed,
  shareProgramClearStatus,
  mergeProgramFailed,
  mergeProgramReset,
} from '../actions/userActions';
import { LanguageCode } from '@visikon/core-models/content';

export interface IShareProgramSmsActionType {
  phoneNumber: string;
}

export interface IShareProgramEmailActionType {
  name: string;
  email: string;
}

export type MergeErrorString =
  | 'merge_error_code_parameter_missing'
  | 'merge_error_user_not_logged_in'
  | 'merge_error_current_user_not_found'
  | 'merge_error_unknown_code'
  | 'merge_error_same_code'
  | 'merge_error_code_already_used'
  | 'merge_error_new_language'
  | 'merge_error_unknown_error';
export interface IShareProgramState {
  isRunning: boolean;
  errorMsg?: string;
  mergeResourcesError?: { error: MergeErrorString; availableLanguages?: LanguageCode[] };
  status: 'none' | 'success' | 'failed';
}

const initialState: IShareProgramState = { isRunning: false, status: 'none', errorMsg: undefined, mergeResourcesError: undefined };

export const shareProgramReducer: Reducer<IShareProgramState> = (state = { ...initialState }, action) => {
  // User info
  if (isType(action, shareProgramSms)) {
    return { ...state, isRunning: true };
  }
  if (isType(action, shareProgramSuccess)) {
    return { ...state, isRunning: false, status: 'success', errorMsg: undefined };
  }
  if (isType(action, shareProgramFailed)) {
    return { ...state, isRunning: false, status: 'failed', errorMsg: action.payload };
  }
  if (isType(action, shareProgramClearStatus)) {
    return { ...initialState };
  }

  // Merge programs
  if (isType(action, mergeProgramFailed)) {
    return {
      ...state,
      mergeResourcesError: action.payload,
    };
  }
  if (isType(action, mergeProgramReset)) {
    return {
      ...state,
      mergeResourcesError: undefined,
    };
  }

  return state;
};
