import { GetStorage } from './Storage';

export const TREATMENT_CARD_DISMISSED = 'treatment-card-dismissed';

export type DismissedPrograms = {
  [key: string]: string;
}[];
// TODO: make sure this uses the newly implemented localStorage mechanics
export const TreatmentCardStorage = {
  get: async () => {
    const jsonString = await GetStorage().getItem(TREATMENT_CARD_DISMISSED);
    return jsonString ? (JSON.parse(jsonString) as DismissedPrograms) : undefined;
  },
  set: async (programs: DismissedPrograms) => {
    await GetStorage().setItem(TREATMENT_CARD_DISMISSED, JSON.stringify(programs));
  },
  clear: async () => {
    await GetStorage().removeItem(TREATMENT_CARD_DISMISSED);
  },
};
