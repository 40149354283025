import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
  mergeProgram,
  mergeProgramFailed,
  mergeProgramReset,
  setNewProgram,
  shareProgramSms,
  shareProgramFailed,
  shareProgramSuccess,
  shareProgramEmail,
} from '../actions/userActions';
import { changeCountry, changeProgram } from '../actions/resourcesActions';
import { IShareProgramEmailActionType, IShareProgramSmsActionType, MergeErrorString } from '../reducers/shareProgramReducer';
import { fetchGet, fetchPost, selfServiceApiCall } from '@visikon/utils/src/utils';
import { backbone, useBackbone } from '@visikon/backbone/src';
import { ProgramsStorage } from '../local-storage/programs-storage';
import { Country } from '@visikon/core-models/i18n/languages';
import { tracker } from '@visikon/tracker/src';

export const listProgramLanguages = (code: string) => `content/mitforlobPack/translations/${code}`;

function* handleShareEmailProgramSaga(action: Action<IShareProgramEmailActionType>) {
  const language = backbone.store.getState().country.languageCode;
  const body = { mailTo: action.payload.email, from: action.payload.name, languageCode: language };

  const programId = yield select((state) => state.resources.content?._id);

  try {
    const result: Response = yield call(fetchPost, `users/createReferencedUser/${programId}`, {}, body);
    if (result.ok) {
      yield put(shareProgramSuccess());
    } else {
      let error = 'Unknown error';
      const r = yield call([result, result.json]);
      if (r.error) {
        error = r.error;
      }
      yield put(shareProgramFailed(error));
    }
  } catch (e: any) {
    console.log("Couldn't share program: ", e.message);
    yield put(shareProgramFailed(e.message));
  }
}

function* handleShareSmsProgramSaga(action: Action<IShareProgramSmsActionType>) {
  const bearerToken = useBackbone.getState().authToken;
  const locale = backbone.store.getState().country.languageCode;
  const programId = yield select((state) => state.resources.content?._id);
  const body = { ...action.payload, locale, programId, bearerToken };

  try {
    const result: Response = yield call(selfServiceApiCall, `sms/shareProgram`, {
      body: JSON.stringify(body),
      method: 'POST',
    });
    if (result.ok) {
      yield put(shareProgramSuccess());
    } else {
      let error = 'Unknown error';
      const r = yield call([result, result.json]);
      if (r.error) {
        error = r.error;
      }
      yield put(shareProgramFailed(error));
    }
  } catch (e: any) {
    console.log("Couldn't share program: ", e.message);
    yield put(shareProgramFailed(e.message));
  }
}

function* mergeProgramSaga(action: Action<{ code: string; country?: Country }>) {
  const activeCountry = backbone.store.getState().country;

  const { activeProgramId } = yield ProgramsStorage.get() || {};
  const { code, country } = action.payload;
  try {
    // If language is _not_ specified, check program has active language
    if (!country) {
      const programLanguages: any[] | any = yield call(fetchGet, listProgramLanguages(code));

      if (programLanguages.error) {
        yield put(mergeProgramFailed({ error: 'merge_error_unknown_code' }));
        tracker.trackEvent('HomeScreen', 'AddProgram', 'Failure', programLanguages.error);
        return;
      }

      if (programLanguages.find((l) => l.language === activeCountry.languageCode) === undefined) {
        // new language!!
        yield put(mergeProgramFailed({ error: 'merge_error_new_language', availableLanguages: programLanguages.map((l) => l.language) }));
        return;
      }
    }

    const body = { code };
    const response = yield call(fetchPost, 'users/mergeCode', {}, body);
    const result = yield call([response, response.json]);

    // If the language is different from the active language, change it
    if (country && country.languageCode !== activeCountry.languageCode) {
      yield put(changeCountry(country));
    }

    if (result.error) {
      const error = `merge_error_${result.error}` as MergeErrorString;
      tracker.trackEvent('HomeScreen', 'AddProgram', 'Failure', error);
      yield put(mergeProgramFailed({ error }));
      return;
    }

    tracker.trackEvent('HomeScreen', 'AddProgram', 'Success');
    yield ProgramsStorage.clear();

    const newResources: string[] = result.newResources;

    if (newResources) {
      const newId = newResources.find((id) => id !== activeProgramId);
      yield put(mergeProgramReset());
      if (newId) {
        console.log("New program's id: ", newId);
        yield put(setNewProgram(newId));
        yield put(changeProgram({ id: newId }));
      }
    }
  } catch (e: any) {
    console.warn(e);
    yield put(mergeProgramFailed({ error: 'merge_error_unknown_error' }));
  }
}

export function* shareProgramSaga() {
  yield takeEvery(shareProgramEmail, handleShareEmailProgramSaga);
  yield takeEvery(shareProgramSms, handleShareSmsProgramSaga);
  yield takeEvery(mergeProgram, mergeProgramSaga);
}
