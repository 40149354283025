import { IState } from '../reducers/reducer';
import { AUTH_CODE_ROUTE } from '../screens/startcode/AuthRouter';
import { START_CODE_ROUTE } from '../screens/startcode/StartCodeRouter';
import { SET_USER_ROUTE } from '../screens/login/SetUserRouter';

export const getIsReferencedUser = (state: IState) => state.auth.referencedUser;

export function isStartAuthOrSetUserRoute() {
  return (
    window.location.pathname.startsWith(AUTH_CODE_ROUTE) ||
    window.location.pathname.startsWith(START_CODE_ROUTE) ||
    window.location.pathname.includes(SET_USER_ROUTE)
  );
}
