import { Button } from '@visikon/spine';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SessionStorage } from '../../local-storage/session-storage';
import { i18n } from '@visikon/mytreatment/src/internationalization/i18n';
import { useLanguage } from '../../common/selectors';

type Props = { timeout?: number };
const StyledButton = styled(Button)`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const defaultTimeout = 7;
const secondsToMilliseconds = (seconds: number) => seconds * 1000;

export function CancelSessionButton({ timeout }: Props) {
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const language = useLanguage();

  const cancelSession = React.useCallback(() => {
    SessionStorage.clear();
    history.go(0);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, secondsToMilliseconds(timeout ?? defaultTimeout));

    return () => clearTimeout(timer);
  }, [timeout]);

  return show ? (
    <StyledButton className="primary rounded" onClick={cancelSession}>
      {i18n('cancel', language)}
    </StyledButton>
  ) : null;
}
