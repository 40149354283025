import { Link } from 'react-router-dom';
import { SideBarContainer } from '../../components/SideBarContainer';
import { I18N } from '../../internationalization/AppText';
import { FlexView, SideBarContent } from '../../components/styled/ContentAreaContainers';
import FamilyImage from '../../images/family.png';
import { IState } from '../../reducers/reducer';
import { backbone } from '@visikon/backbone/src';
import { GetButton } from '../program/SmartBanner.styles';
import { SHARE_PROGRAM_ROUTE } from '../share/shareProgramContainer';
import { useSelector } from 'react-redux';

export const ShareProgramSideBar = () => {
  const isEmbbeded = backbone.useIsEmbedded();
  const referencedUser = useSelector((state: IState) => state.auth.referencedUser);
  const programId = backbone.store.getState().program?.id;
  const shareProgramUrl = `/${programId}${SHARE_PROGRAM_ROUTE}`;

  if (referencedUser || isEmbbeded) {
    return <SideBarContainer />;
  }

  return (
    <SideBarContainer>
      <img src={FamilyImage} width="100%" alt="" />
      <br />
      <SideBarContent isVideoPlayerOpen={false}>
        <h2 style={{ fontSize: '1em' }}>
          <I18N text={'share_header_patient'} />
        </h2>
        <p>
          <I18N text={'share_allow_friends_and_family'} />.
        </p>
        <p>
          <I18N text={'share_no_personal_info'} />
        </p>
        <FlexView style={{ justifyContent: 'center', marginTop: '3em' }}>
          <Link to={shareProgramUrl}>
            <GetButton variant="outline">
              <I18N text="show_more" />
            </GetButton>
          </Link>
        </FlexView>
      </SideBarContent>
    </SideBarContainer>
  );
};
