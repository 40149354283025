import * as React from 'react';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { ProgramInfo } from '../../common/listPrograms';
import { PopupDialog } from '../../components/popup/PopupDialog';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

const Title = styled.div``;
const SubTitle = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const ArchiveBody1 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.border};
  margin-top: 24px;
`;

const ArchiveBody2 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

type Props = {
  program: ProgramInfo;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ArchiveProgramDialog = ({ program, onConfirm, onCancel }: Props) => {
  const title = (
    <>
      <Title>
        <I18N text={'home_archive_title'} />
      </Title>
      <SubTitle>{program.name}</SubTitle>
    </>
  );

  return (
    <PopupDialog
      title={title}
      confirmAction={{ onClick: onConfirm, variant: 'danger', text: i18N('home_archive_program_accept'), style: { textTransform: 'none' } }}
      cancelAction={{ onClick: onCancel, text: i18N('home_archive_program_cancel'), style: { textTransform: 'none' } }}
      onDismiss={onCancel}
      content={
        <>
          <ArchiveBody1>
            <I18N text={'home_archive_body1'} replace={{ $PROGRAM_NAME: program.name }} />
          </ArchiveBody1>
          <ArchiveBody2>
            <I18N text={'home_archive_body2'} replace={{ $PROGRAM_NAME: program.name }} />
          </ArchiveBody2>
          <Divider />
        </>
      }
    />
  );
};
