import { useMutation } from '@tanstack/react-query';
import { HttpError, useApiClient } from './query-utils';
import { LoginResponse, StaffLoginRequest, UserLoginRequest } from './auth-api.models';
import { backbone } from '@visikon/backbone/src';
import { tracker } from '@visikon/tracker/src';
import { useDispatch } from 'react-redux';
import * as AuthActions from '../actions/authActions';
import { setError } from '../actions/resourcesActions';
import { SessionStorage } from '../local-storage/session-storage';
import { ProgramCodeStorage } from '../local-storage/program-code-storage';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '../screens/home/HomeScreen';

export function useAuthenticateByCode() {
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const history = useHistory();

  return useMutation<LoginResponse, HttpError, UserLoginRequest>(
    async (payload: UserLoginRequest) => {
      try {
        const translatedCode = await ProgramCodeStorage.translateCode(payload.code);
        // Added a 500ms delay to show the spinner
        await new Promise((resolve) => setTimeout(resolve, 500));
        return (await apiClient.post<any>('authEndUser', translatedCode)).data;
      } catch (error: any) {
        const responseCode = error?.response.data.error as string;
        console.log('error', error, responseCode);
        dispatch(setError(responseCode));
        throw HttpError.axiosError(error);
      }
    },
    {
      onSuccess: async (data, payload) => {
        const { token, realCode } = data;

        tracker.trackEvent('Auth', 'loginSuccess');
        await SessionStorage.set(data);
        await ProgramCodeStorage.persist(payload.code, realCode);
        dispatch(AuthActions.authenticateSuccessful(data));
        dispatch(setError(undefined));
        backbone.setAuthToken(token);
        history.push(HOME_ROUTE, { from: history.location.pathname });
      },
    },
  );
}

export function useAuthenticateByEmail() {
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const history = useHistory();

  return useMutation<LoginResponse, HttpError, StaffLoginRequest>(
    async (payload: StaffLoginRequest) => {
      try {
        return (await apiClient.post<any>('auth', payload)).data;
      } catch (error) {
        throw HttpError.axiosError(error);
      }
    },
    {
      onSuccess: async (data) => {
        const { token } = data;

        tracker.trackEvent('Auth', 'staffLoginSuccess');
        await SessionStorage.set(data);

        dispatch(AuthActions.authenticateSuccessful(data));
        backbone.setAuthToken(token);
        history.push(HOME_ROUTE, { from: history.location.pathname });
      },
    },
  );
}
