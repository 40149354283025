import { I18N } from '@visikon/mytreatment/src/internationalization/AppText';
import { useDialogContext } from '@visikon/backbone';
import { Button, Dialog } from '@visikon/spine';
import { ModalWrapper } from './ModalWrapper';
import { LazyImage } from '@visikon/utils/src/LazyImage';
import styled from 'styled-components';

export function NotificationReminderDialog({ text, image_url }) {
  const dialog = useDialogContext();
  const handleDialogClick = (event) => {
    // Stop the click event from propagating to ModalWrapper
    event.stopPropagation();
  };

  return (
    <ModalWrapper onClick={dialog.close}>
      <Dialog
        onClick={handleDialogClick}
        open
        actions={
          <Button onClick={dialog.close}>
            <I18N text="close" />
          </Button>
        }
      >
        {text}
        <ImgWrapper>
          <LazyImage imageUrl={image_url} />
        </ImgWrapper>
      </Dialog>
    </ModalWrapper>
  );
}

const ImgWrapper = styled.div`
  & > img {
    user-select: none;
    height: 100%;
    width: 100%;
    object-fit: contain;

    aspect-ratio: 16/9;
  }
`;
