import { useState } from 'react';
import { PopupDialog } from '../../components/popup/PopupDialog';
import * as React from 'react';
import { i18n } from '../../internationalization/i18n';
import * as Styles from './LoginContainer.styles';
import { I18N } from '../../internationalization/AppText';

export function usePersonalCodeField() {
  const [showPopup, setShowPopup] = useState(false);

  const personalCodeTitle = i18n('login_code_guiding_title');
  const personalCodeContent = (
    <div style={{ textAlign: 'initial' }}>
      <Styles.GuidingText>
        <I18N text="login_code_guiding_text" />
      </Styles.GuidingText>

      <Styles.GuidingTextHelp>
        <I18N text="login_code_guiding_text_help" />
      </Styles.GuidingTextHelp>
    </div>
  );

  const personalCodeLink = (
    <>
      <Styles.GuidingTitle onClick={() => setShowPopup(true)}>
        <I18N text="login_code_guiding_title" />
      </Styles.GuidingTitle>
      {showPopup && <PopupDialog title={personalCodeTitle} onDismiss={() => setShowPopup(false)} content={personalCodeContent} />}
    </>
  );

  return { personalCodeLink };
}
