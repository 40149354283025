import { backbone } from '@visikon/backbone/src';
import { useEffect, useState } from 'react';
import { MediaFileUtils } from './MediaFileUtils';

export const selfServiceApiCall = async (url: string, options?: RequestInit) => {
  const { hosts, apiKey } = backbone.store.getState();

  const absoluteUrl = `${hosts.selfServiceApi}${url}`;
  let headers: HeadersInit = {
    'Vcore-API-Key': apiKey,
  };

  if (options?.headers) {
    headers = { ...headers, ...options.headers };
  }

  return fetch(absoluteUrl, {
    ...options,
    method: 'POST',
    headers,
  });
};

function coreApiCall(url: string, options?: RequestInit) {
  const { hosts, apiKey, client, authToken, country } = backbone.store.getState();

  const absoluteUrl = `${hosts.core}${url}`;
  let headers: HeadersInit = {
    'Vcore-API-Key': apiKey,
    'mitforlob-app-version': `${client?.version} web`,
    'vcore-language-code': country?.languageCode,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  if (options?.headers) {
    headers = { ...headers, ...options.headers };
  }

  return fetch(absoluteUrl, {
    ...options,
    headers,
  });
}

export async function fetchGet(url: string) {
  if (url === undefined || url.length === 0) {
    return;
  }

  const req = await coreApiCall(url);
  return await req.json();
}

export async function fetchBlobJson(path: string) {
  const baseUrl = backbone.store.getState().hosts.blobs;
  const response = await fetch(`${baseUrl}${path}`);

  return await response.json();
}

export async function fetchPost(url: string, extraHeaders: any, data: any = {}) {
  return coreApiCall(url, {
    method: 'POST',
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache',
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      ...extraHeaders,
    },
    redirect: 'follow',
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data),
  });
}

export async function testConnection({ timeout = 20000 } = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', '');
    xhr.timeout = timeout;
    xhr.onload = () => {
      resolve('Connection successful');
    };
    xhr.ontimeout = () => {
      reject('Request timed out');
    };
    xhr.onerror = () => {
      const errorMessage = xhr.status === 0 ? 'network_error' : `Request failed with status ${xhr.status}`;
      reject(errorMessage);
    };
    xhr.send();
  });
}

export function useImageFromUri(imgId?: string | null) {
  const [uri, setUri] = useState('');
  useEffect(() => {
    if (imgId) {
      const imgUri = MediaFileUtils.getUriForImage(imgId, 'da');
      setUri(imgUri);
    }
  }, [imgId]);
  return uri;
}
