import {GetStorage} from "./Storage";

function toggleStorage(storageKey: string) {
  return {
    get: async () => await GetStorage().getItem(storageKey),

    enabled: async () => !! await GetStorage().getItem(storageKey),

    toggle: async (value?: string | null | undefined) => {
      if (! await GetStorage().getItem(storageKey)) {
        await GetStorage().setItem(storageKey, value || 'true');
      } else {
        await GetStorage().removeItem(storageKey);
      }
    },
  };
}

export const UsernameStorage = toggleStorage('USERNAME');
export const ShowStaffStorage = toggleStorage('ALWAYS_SHOW_STAFF_KEY');
