import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { authenticateSuccessful, clearLoginProgress, externalUserSet, setAuthCode, setBearerToken, setStartCode } from '../actions/authActions';
import { IUserDataState } from './userDataReducer';
import { backbone } from '@visikon/backbone/src';
import { UserLoginRequest } from '../api/auth-api.models';

export interface IAuthRequest {
  readonly code: string;
  loggedIn?: boolean;
}

export type ReAuthenticateRequest = IAuthRequest & {
  authenticate: (payload: UserLoginRequest) => void;
};

export type UserContext = {
  token: string;
  programId: string;
  languageCode: string;
};

export interface IAuthSuccessResult {
  token: string;
  userData: IUserDataState;
  referencedUser: boolean;
  firstLogin: boolean;
  activatedAt: number;
}

export interface IAuthError {
  readonly msg: string;
}

export interface IState {
  readonly referencedUser?: boolean;
  readonly externalUserSet?: boolean;
  readonly firstLogin?: boolean;
  readonly activatedAt?: number;
  readonly error?: string;
  _id?: string;
  readonly staffLoginError?: string;
  readonly loginProcess?: ILoginInProcess;
}

export interface ILoginInProcess {
  authCode?: string;
  startCode?: string;
}

const initialState: IState = {
  referencedUser: undefined,
  firstLogin: undefined,
  loginProcess: undefined,
};

export function authReducer(state: IState = { ...initialState }, action: Action): IState {
  if (isType(action, authenticateSuccessful)) {
    const user = backbone.setAuthToken(action.payload.token);

    const { referencedUser, firstLogin, activatedAt } = action.payload;
    return {
      ...state,
      referencedUser,
      firstLogin,
      activatedAt,
      _id: user?._id,
    };
  }

  if (isType(action, setAuthCode)) {
    return { ...state, loginProcess: { authCode: action.payload } };
  }

  if (isType(action, clearLoginProgress)) {
    return { ...state, loginProcess: undefined };
  }

  if (isType(action, setStartCode)) {
    return { ...state, loginProcess: { startCode: action.payload } };
  }

  if (isType(action, setBearerToken)) {
    backbone.setAuthToken(action.payload);
    return state;
  }

  if (isType(action, externalUserSet)) {
    return { ...state, externalUserSet: true };
  }

  return state;
}
