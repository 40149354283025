import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { Department } from '../../reducers/resourcesReducer';
import { setSeenPrivacyPolicy } from '../../actions/userActions';
import { useState } from 'react';
import { setIsShowingPrivacyPolicy } from '../../actions/resourcesActions';
import { useIsUserDataNotLoaded, usePrivacyPolicyAcceptedEvent, useSeenPrivacyPolicy } from '../../common/selectors';
import { ProgramsStorage, StoredPrograms } from '../../local-storage/programs-storage';
import { usePromise } from '../../common/utils';
import { i18N, I18N } from '../../internationalization/AppText';
import { PopupDialog } from '../../components/popup/PopupDialog';

const DATA_POLICY_URL = 'http://visikon.com/mitforlob/datapolitik/';

export const PrivacyPopupContainer = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  const seenPrivacyPolicy = useSeenPrivacyPolicy();
  const privacyPolicyAcceptedEvent = usePrivacyPolicyAcceptedEvent();
  const isUserDataNotLoaded = useIsUserDataNotLoaded();
  const programs = usePromise<StoredPrograms>(ProgramsStorage.get);
  const activeProgramId = programs?.availablePrograms?.programs[0]._id;
  const programLoaded = Boolean(activeProgramId);

  const department = useSelector<IState, Department | undefined>((state) => state.resources.content?.department);
  const programName = useSelector<IState, string | undefined>((state) => state.resources.content?.name);

  React.useEffect(() => {
    const show = programLoaded && !seenPrivacyPolicy && !isUserDataNotLoaded;
    if (show) {
      setModalVisible(true);
    }
    dispatch(setIsShowingPrivacyPolicy(show));
  }, [seenPrivacyPolicy, programLoaded, isUserDataNotLoaded]);

  const handleConfirm = () => {
    dispatch(setSeenPrivacyPolicy(new Date()));
    dispatch(setIsShowingPrivacyPolicy(false));
    setModalVisible(false);
    if (privacyPolicyAcceptedEvent) {
      privacyPolicyAcceptedEvent();
    }
  };

  const content = (
    <>
      <I18N text="data_policy_body" />
      <br />
      <br />
      <a href={DATA_POLICY_URL} target="_blank" rel="noreferrer">
        <I18N text="data_policy_read_more" />
      </a>
    </>
  );

  const translatedTitle = i18N('data_policy');

  if (seenPrivacyPolicy || !department || !programName) {
    return null;
  }

  return (
    <div>
      {modalVisible && <PopupDialog slideDirection={'up'} title={translatedTitle} confirmAction={{ onClick: handleConfirm }} content={content} />}
    </div>
  );
};
