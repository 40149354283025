import React from 'react';
import { classes } from 'typestyle';
import { Styles } from '../styles';

function HeaderFactory(elementname: keyof React.ReactHTML, className: string) {
  return function (props?: React.HTMLAttributes<HTMLHeadingElement>) {
    return React.createElement(elementname, {
      ...props,
      className: classes(className, props?.className),
    });
  };
}

export const Mammoth = HeaderFactory('h1', Styles.size.mammoth);

export const Elephant = HeaderFactory('h1', Styles.size.elephant);

export const Epic = HeaderFactory('h1', Styles.size.epic);

export const H1 = HeaderFactory('h1', Styles.size.h1);

export const H2 = HeaderFactory('h2', Styles.size.h2);

export const H3 = HeaderFactory('h3', Styles.size.h3);

export const H4 = HeaderFactory('h4', Styles.size.h4);

export const H5 = HeaderFactory('h5', Styles.size.h5);
