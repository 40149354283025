import { put, take, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import * as AuthActions from '../actions/authActions';
import { externalUserSet, logOut, logOutSuccessful, setAuthCode, setBearerToken } from '../actions/authActions';
import { LOCAL_STORAGE_USER_DATA_KEY } from '../common/config';
import { UserContext } from '../reducers/authReducer';
import { tracker } from '@visikon/tracker/src';
import { backbone } from '@visikon/backbone/src';
import { getPreferredBrowserLanguage } from '@visikon/backbone/src/languages';
import { SessionStorage } from '../local-storage/session-storage';
import { ProgramsStorage } from '../local-storage/programs-storage';
import { GetStorage } from '../local-storage/Storage';
import { clearUserData } from '../actions/userActions';
import { getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { changeCountry, changeProgram, fetchProgramSuccess } from '../actions/resourcesActions';

function* logoutAndSetAuthCode(action: Action<string>) {
  yield put(logOut());
  yield take(logOutSuccessful); // Wait for logout to complete
  yield put(setAuthCode(action.payload));
}
function* logoutSetUserContext(action: Action<UserContext>) {
  const userContext = action.payload;
  yield put(logOut());
  yield take(logOutSuccessful); // Wait for logout to complete
  yield put(setBearerToken(userContext.token));
  yield put(externalUserSet());

  if (userContext.languageCode) {
    const country = getCountryByLanguageCode(userContext.languageCode);
    yield put(changeCountry(country));
  }
  console.log('userContext.programId', userContext);
  yield put(changeProgram({ id: userContext.programId }));
  yield take(fetchProgramSuccess);
}

function* loadAuthenticationFromLocalStorage() {
  try {
    // Load user data, including token
    const session = yield SessionStorage.get();
    if (session) {
      const { token, referencedUser, activatedAt } = session;

      const userDataJson = yield GetStorage().getItem(LOCAL_STORAGE_USER_DATA_KEY);
      let userData;
      if (userDataJson) {
        try {
          userData = JSON.parse(userDataJson);
        } catch (e: any) {
          tracker.trackEvent('Auth', 'loginFailed', "localstorage: Couldn't reinitialize user data");
          console.warn("Couldn't reinitialize user data");
        }
      }
      // TODO: Check for expiration
      backbone.setAuthToken(token);
      yield put(
        AuthActions.authenticateSuccessful({
          token,
          referencedUser,
          firstLogin: false,
          userData,
          activatedAt,
        }),
      );
    }
  } catch (e: any) {
    tracker.trackEvent('Auth', 'loginFailed', `localstorage: ${e.message}`);
  }
}

function* logOutSaga(_: Action<void>) {
  console.log('Logging out');
  const preferredBrowserLanguage = getPreferredBrowserLanguage();
  const country = getCountryByLanguageCode(preferredBrowserLanguage);
  try {
    backbone.store.setState({
      ...backbone.store.getState(),
      authToken: undefined,
      deviceId: undefined,
      user: undefined,
      program: undefined,
      country,
    });
    yield ProgramsStorage.clear();
    yield SessionStorage.clear();
    yield put(clearUserData());
    yield GetStorage().removeItem('STARTCODE');
    yield GetStorage().removeItem('UNIQUEDEVICEID');
    tracker.trackEvent('Auth', 'logout');
    yield put(AuthActions.logOutSuccessful());
  } catch (e: any) {
    console.log('Error logging out', e);
    yield put(AuthActions.logOutSuccessful());
  }
}

export function* authSaga() {
  yield takeEvery(AuthActions.authenticateFromStorage, loadAuthenticationFromLocalStorage);
  yield takeEvery(AuthActions.logOut, logOutSaga);
  yield takeEvery(AuthActions.logoutAndSetAuthCode, logoutAndSetAuthCode);
  yield takeEvery(AuthActions.setUserContext, logoutSetUserContext);
}
