import { v4 as uuidv4 } from 'uuid';
import { GetStorage } from './Storage';

const STORAGE_START_CODE_KEY = 'ACCESS_KEYS';
const STORAGE_DEVICE_ID_KEY = 'UNIQUE_DEVICE_ID';
const LOCAL_STORAGE_SURVEY_DISMISS = 'LOCAL_STORAGE_SURVEY_DISMISS';

type AccessCode = {
  startCode: string;
  accessCode: string;
};
type AccessCodes = Array<AccessCode>;

export const LocalStorage = {
  getAccessCode: async (startCode: string) => {
    const accessCodes = await LocalStorage.getAccessCodes();
    return accessCodes.filter((entry) => entry.startCode === startCode)[0]?.accessCode;
  },
  saveAccessCode: async (accessCode: AccessCode) => {
    const accessCodes = await LocalStorage.getAccessCodes();
    await LocalStorage.saveAccessCodes([...accessCodes, accessCode]);
  },

  getAccessCodes: async () => {
    const storedAccess = (await GetStorage().getItem(STORAGE_START_CODE_KEY)) || '[]';
    return JSON.parse(storedAccess) as AccessCodes;
  },
  saveAccessCodes: async (codes: AccessCodes) => {
    await GetStorage().setItem(STORAGE_START_CODE_KEY, JSON.stringify(codes));
  },

  getDeviceId: async () => {
    const deviceId = await GetStorage().getItem(STORAGE_DEVICE_ID_KEY);

    if (deviceId) {
      return deviceId;
    }

    const uuid = uuidv4();
    await GetStorage().setItem(STORAGE_DEVICE_ID_KEY, uuid);

    return uuid;
  },

  getIsSurveyDismissed: async (): Promise<boolean> => {
    const dateFromStorage = await GetStorage().getItem(LOCAL_STORAGE_SURVEY_DISMISS);
    const date = Date.parse(dateFromStorage || '');
    if (!date) {
      return false;
    }
    return date > Date.now() - 1000 * 60 * 60 * 24; //24 hours ago
  },

  setSurveyDismiss: async (value?: boolean) => {
    if (!value) {
      await GetStorage().removeItem(LOCAL_STORAGE_SURVEY_DISMISS);
      return;
    }
    await GetStorage().setItem(LOCAL_STORAGE_SURVEY_DISMISS, new Date().toISOString());
  },
};
