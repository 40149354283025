import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tracker } from '@visikon/tracker/src';
import { SetDocumentTitle } from '../../internationalization/AppText';
import { updateVideoProgress } from '../../actions/userActions';
import { capitalizeFirstLetter, getVideoInfo } from '../../common/utils';
import { MediaFileUtils } from '@visikon/utils';
import { getTranslation } from '@visikon/core-models/typeUtils';
import * as Styles from './PlayerContainer.styles';
import {
  checkIsFullscreen,
  destroyVideo,
  exitFullscreen,
  requestFullscreen,
  useVideoWatchedPercentage,
  useFullScreenChangeEvent,
} from './videoUtils';
import { useId, useIsPrivacyPolicyShowing, useLanguage, useWholeContent } from '../../common/selectors';
import { calculatePercentage } from '../../common/numbers';
import { isFullScreenAction, Video } from '../../reducers/resourcesReducer';
import { useLocation } from 'react-router-dom';
import { useBackbone } from '@visikon/backbone/src';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { useEffect } from 'react';
import videoPlaceHolder from '../../images/videoPlaceholder.png';
import VideoThumbnail from '../../components/VideoThumbnail';
import { setPrivacyPolicyAcceptedEvent } from '../../actions/resourcesActions';
import { useScreenOrientationEvent } from './ScreenOrientation.util';

export const VIDEO_PLAYER_STR = '/videoplayer/';
export const VIDEO_PLAYER_ROUTE = `${VIDEO_PLAYER_STR}:id`;

export const useIsVideoPlayerOpen = () => {
  const location = useLocation();
  return location.pathname.indexOf(VIDEO_PLAYER_STR) !== -1;
};

type VideoEvent = React.BaseSyntheticEvent<Event, HTMLVideoElement, HTMLVideoElement>;
type VideoSubtitleTrack = {
  key: string;
  label: string;
  src: string;
};
type VideoInfo = Video & { subtitles?: VideoSubtitleTrack[] };

interface IPropsFromState {
  videoInfo?: VideoInfo;
}

function onContextMenu(event: React.MouseEvent<HTMLVideoElement>) {
  event.preventDefault();
  event.stopPropagation();
  return false;
}

const CPlayerContainer = ({ videoInfo }: IPropsFromState) => {
  const dispatch = useDispatch();
  const language = useLanguage();
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [loop, setLoop] = React.useState(false);
  const isPrivacyPolicyShowing = useIsPrivacyPolicyShowing();
  const videoId = videoInfo ? videoInfo._id : '';
  const watchedPercentage = useVideoWatchedPercentage(videoId);
  const { env, hosts } = useBackbone((state) => ({
    env: state.env,
    hosts: state.hosts,
  }));

  function UpdateLocalVideoProgress(videoElem: HTMLVideoElement) {
    const { currentTime, duration } = videoElem;

    const percentage = calculatePercentage(currentTime, duration);

    if (!Number.isNaN(percentage)) {
      dispatch(updateVideoProgress({ id: videoId, percentage }));
    }
  }

  useScreenOrientationEvent((type) => {
    console.log('ScreeOrientation changed! New Orientation', type, type.startsWith('landscape'));

    if (videoRef.current && type.startsWith('landscape')) {
      requestFullscreen(videoRef.current);
    }
  });

  useFullScreenChangeEvent(videoRef.current, () => {
    const isFullscreen = checkIsFullscreen();
    tracker.trackEvent('Video', isFullscreen ? 'videoFullscreenEnter' : 'videoFullscreenExit');
  });

  // Code to handle the VIDEO_FULLSCREEN alias action
  const fullScreenAction = useSelector(isFullScreenAction(videoId));
  useEffect(() => {
    if (isPrivacyPolicyShowing && fullScreenAction && setPrivacyPolicyAcceptedEvent) {
      dispatch(
        setPrivacyPolicyAcceptedEvent(() => {
          setLoop(true);
          setTimeout(() => {
            requestFullscreen(videoRef.current);
          }, 300);
        }),
      );
    }
  }, [isPrivacyPolicyShowing, videoRef]);

  // Destroy video on unmount
  useEffect(() => {
    // Need to keep the video element in variable
    // Because unmounting is asynchronously and the reference may have been set to null
    // When executing the unmounting callback
    // See https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
    tracker.trackScreen('VideoScreen');
    const videoElem = videoRef.current;

    return () => {
      if (videoElem) {
        UpdateLocalVideoProgress(videoElem);
        destroyVideo(videoElem);
        tracker.trackEvent('Video', 'leave');
      }
    };
  }, []);

  const trackPlayerEvent = (evt: string) => {
    const video = videoRef.current;
    let currentTime = video?.currentTime ?? 0;
    const duration = video?.duration ?? 0;

    const percentage = duration ? Math.round((currentTime / duration) * 100) : 0;
    const eventString = `video${capitalizeFirstLetter(evt)}`;
    let progressString = `percent-${percentage}`;

    if (evt === 'start') {
      const info = videoInfo!;
      progressString = `${info._id}-${info.trackingKey}`;
    }
    tracker.trackEvent('Video', eventString, progressString);
  };

  const pauseEvent = (ev: VideoEvent) => {
    if (ev.target.seeking) return;

    UpdateLocalVideoProgress(ev.target);
  };

  const endEvent = () => {
    exitFullscreen();
    trackPlayerEvent('finished');
  };

  const onVideoLoaded = (ev: VideoEvent) => {
    const video = ev.target;
    video.currentTime = (video.duration / 100) * watchedPercentage;
  };

  const videoSourced = videoInfo && getTranslation(videoInfo, language);
  if (!videoSourced) {
    return null;
  }

  let videoUrl = `${hosts.blobs}${videoSourced.src}`;
  if (env === EnvironmentName.Local) {
    videoUrl = `${hosts.core}media/video/file/${videoInfo._id}/${videoSourced.language}?modified=${videoSourced.lastModified}`;
  }

  if (!videoInfo && videoId !== 'undefined') {
    return (
      <h5>
        Loading...
        <pre>{JSON.stringify(videoId)}</pre>
      </h5>
    );
  }

  const thumbSrc = MediaFileUtils.getUriForThumbnail(videoInfo, language) || videoPlaceHolder;
  return (
    <VideoPlayerContainer videoName={videoInfo.name}>
      {isPrivacyPolicyShowing ? (
        <VideoThumbnail thumbUrl={thumbSrc} spinner={true} />
      ) : (
        <Styles.VideoViewer
          crossOrigin="anonymous"
          ref={videoRef}
          autoPlay
          controls
          src={videoUrl}
          onPause={pauseEvent}
          onEnded={endEvent}
          onContextMenu={onContextMenu}
          onLoadedMetadata={onVideoLoaded}
          onDurationChange={() => trackPlayerEvent('start')}
          controlsList="nodownload"
          playsInline
          loop={loop}
        >
          {videoSourced.subtitles &&
            videoSourced.subtitles.map((entry) => (
              <track
                key={entry.key}
                label={entry.label}
                kind="subtitles"
                srcLang={entry.key}
                src={MediaFileUtils.getUriForSubtitles(videoInfo, language, entry.key)}
              />
            ))}
        </Styles.VideoViewer>
      )}
    </VideoPlayerContainer>
  );
};

interface VideoPlayerContainerProps {
  videoName: string;
}

function VideoPlayerContainer({ videoName, children }: React.PropsWithChildren<VideoPlayerContainerProps>) {
  return (
    <Styles.FlexWrapper>
      <Styles.ContentContainer>
        <SetDocumentTitle text="" textNoTranslation={videoName} />
        <Styles.VideoContainer>{children}</Styles.VideoContainer>

        <Styles.VideoTitle>{videoName}</Styles.VideoTitle>
      </Styles.ContentContainer>
    </Styles.FlexWrapper>
  );
}

export function PlayerContainer() {
  const id = useId();
  const content = useWholeContent();
  const video = getVideoInfo(id, content);
  const props: IPropsFromState = {
    videoInfo: video,
  };

  return <CPlayerContainer {...props} />;
}
