import { MyTreatmentProgramDescription } from '@visikon/core-models/content';
import { GetStorage } from './Storage';

export const STORAGE_PROGRAMS_KEY = 'local-storage-programs-key';

export type StoredPrograms = StoredProgramsData & {
  activeProgram: () => MyTreatmentProgramDescription;
};

type StoredProgramsData = {
  activeProgramId?: string;
  availablePrograms: AvailableProgramsResult;
};

export type AvailableProgramsResult = {
  programs: MyTreatmentProgramDescription[];
  timestamp: number;
};

export function storedProgramsFromString(jsonString?: string | null): StoredPrograms | undefined {
  if (!jsonString) {
    return undefined;
  }

  const parsedObject = JSON.parse(jsonString) as StoredProgramsData;
  return storedProgramsFromObject(parsedObject);
}

export function storedProgramsFromObject(jsonObject?: StoredProgramsData): StoredPrograms | undefined {
  if (!jsonObject) {
    return undefined;
  }

  const matchingProgram = jsonObject.availablePrograms.programs.find((p) => p._id === jsonObject.activeProgramId);
  if (!matchingProgram) {
    jsonObject.activeProgramId = jsonObject.availablePrograms.programs[0]._id;
  }

  return {
    ...jsonObject,
    activeProgram: () =>
      jsonObject.availablePrograms.programs.find((p) => p._id === jsonObject.activeProgramId) || ({} as MyTreatmentProgramDescription),
  };
}

export const ProgramsStorage = {
  get: async () => {
    const jsonString = await GetStorage().getItem(STORAGE_PROGRAMS_KEY);
    return storedProgramsFromString(jsonString);
  },
  set: async (programs: StoredPrograms) => {
    await GetStorage().setItem(STORAGE_PROGRAMS_KEY, JSON.stringify(programs));
  },
  setActiveProgram: async (id?: string) => {
    const programs = await ProgramsStorage.get();
    if (programs) {
      programs.activeProgramId = id;
      await GetStorage().setItem(STORAGE_PROGRAMS_KEY, JSON.stringify(programs));
    }
  },
  clear: async () => {
    await GetStorage().removeItem(STORAGE_PROGRAMS_KEY);
  },
};
