import { classes, style } from 'typestyle';
import { Tokens } from '../../styles/tokens';

export const dialogStyle = style({
  display: 'none',
  flexDirection: 'column',
  padding: 'unset',
  overflow: 'auto',

  marginTop: 'auto',
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',

  borderWidth: 1,
  borderRadius: 12,
  borderStyle: 'solid',
  borderColor: Tokens.color.border,

  boxSizing: 'border-box',

  $nest: {
    '&[open]': {
      display: 'flex',
    },
  },
});

export const dialogHeaderText = style({
  color: Tokens.color.primary,
});

export const dialogContainer = style({
  marginBottom: 8,
  overflow: 'auto',
});
export const dialogSection = style({
  paddingTop: 24,
  paddingRight: 24,
  paddingBottom: 16,
  paddingLeft: 24,
});

export const dialogBody = classes(
  dialogSection,
  style({
    background: Tokens.color.dialogBackground,
  }),
);

export const dialogHeader = classes(
  dialogSection,

  style({
    background: Tokens.color.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Tokens.color.border,
  }),
);
export const dialogActionsContainer = classes(
  dialogBody,
  style({
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '& > button': {
        marginBottom: '12px',
      },

      '& > button:last-child': {
        marginBottom: '0px',
      },
    },
  }),
);
