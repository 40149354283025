// tslint:disable:jsx-no-lambda no-empty-interface
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { PopupScreenCenter } from '../../components/popup/PopupScreenCenter';
import { Hr } from '../../components/styled/HorizontalLine';
import { I18N } from '../../internationalization/AppText';
import { ISurveyResult, SurveyDetailScreen } from '../survey/SurveyDetailScreen';
import WelcomeSurveyScreen from '../survey/WelcomeSurveyScreen';
import { i18n } from '../../internationalization/i18n';
import Cross from '../../images/cross_primary.svg';
import * as Styles from './Survey.styles';
import { useAuth, useLanguage, useUserData, useWholeContent } from '../../common/selectors';
import { MitforlobContent } from '../../reducers/resourcesReducer';
import { LocalStorage } from '../../local-storage/startcodes-storage';
import { usePromise } from '../../common/utils';
import { GetButton } from './SmartBanner.styles';
import { GetButtonWrapper } from './Survey.styles';
import { LocationHooks } from '@visikon/backbone';
import { SHARE_PROGRAM_ROUTE } from '../share/shareProgramContainer';
import { VIDEO_PLAYER_STR } from '../videoPlayer/PlayerContainer';

function isSurveyAvailable(activatedAt: number, offset: number): boolean {
  const MILLIS_IN_ONE_DAY = 86400000;
  const offsetInMillis = MILLIS_IN_ONE_DAY * offset;

  const at10 = new Date(activatedAt).setHours(10, 0);
  const surveyAvailable = at10 + offsetInMillis;

  return surveyAvailable <= Date.now();
}

export const Survey = () => {
  const survey = (useWholeContent() as MitforlobContent).survey;
  const surveysAnswered = useUserData().surveyAnswered;
  const surveyIsAnswered = survey ? surveysAnswered.indexOf(survey._id) !== -1 : false;
  const { activatedAt } = useAuth();
  const language = useLanguage();
  const [screen, setScreen] = useState<'welcome' | 'dialog' | 'exit' | 'finished'>(surveyIsAnswered ? 'finished' : 'welcome');
  const isSurveyDismissed = usePromise(LocalStorage.getIsSurveyDismissed);
  const [showWidget, setShowWidget] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [surveyResult, setSurveyResult] = useState<{ id: string; answers: ISurveyResult[] }>({ id: '', answers: [] });
  const [progress, setProgress] = useState(0);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isSurveyDismissed === undefined) return;
    setShowWidget(!isSurveyDismissed as boolean);
  }, [isSurveyDismissed]);

  const level = LocationHooks.useNavLevel();
  const isHomeScreenLevel = level === -1;

  const isSharePath = window.location.pathname.includes(SHARE_PROGRAM_ROUTE);
  const isVideoplayerPath = window.location.pathname.includes(VIDEO_PLAYER_STR);

  if (survey === undefined || survey.data === undefined || isHomeScreenLevel || isSharePath || isVideoplayerPath) {
    return null;
  }

  const { invitationText, offset } = survey.data;

  // Check if survey is available in time
  if (!isSurveyAvailable(activatedAt || 0, offset || 0)) {
    console.log('not available');
    return null;
  }

  const surveyDismiss = () => {
    LocalStorage.setSurveyDismiss(true);
    setShowWidget(false);
  };

  const surveyConfirmDialog = () => {
    if (screen === 'exit') {
      return (
        <Styles.InnerOverlay>
          <Styles.ConfirmPopup>
            <h1>
              <I18N text="survey_close_warning_header" />
            </h1>
            <Hr color={DeprecatedTokens.border} />
            <div style={{ flex: 1, display: 'flex' }}>
              <p>
                <I18N text="survey_close_warning_text" />
              </p>
            </div>
            <Hr color={DeprecatedTokens.border} />

            <Styles.ButtonWrapper>
              <GetButton
                type="submit"
                variant="outline"
                style={{ marginRight: 10 }}
                color={DeprecatedTokens.negative}
                onClick={() => setScreen('dialog')}
              >
                <I18N text="cancel" />
              </GetButton>
              <Link to="">
                <GetButton type="submit" variant="outline" onClick={() => setShowModal(false)}>
                  OK
                </GetButton>
              </Link>
            </Styles.ButtonWrapper>
          </Styles.ConfirmPopup>
        </Styles.InnerOverlay>
      );
    } else {
      return;
    }
  };

  const invitation = invitationText || i18n('survey_available', language);
  const surveyWidget = (
    <>
      <Styles.SurveyHeader>
        <h3>
          <I18N text="survey_header" />
        </h3>
        <Styles.DismissButton onClick={surveyDismiss} title={i18n('survey_dismiss')}>
          <img alt="" src={Cross} />
        </Styles.DismissButton>
      </Styles.SurveyHeader>
      <Styles.Invitation>{invitation}</Styles.Invitation>
      <GetButtonWrapper>
        <GetButton onClick={() => setShowModal(true)}>
          <I18N text="survey_show" />
        </GetButton>
      </GetButtonWrapper>
    </>
  );

  if (!showWidget) {
    return null;
  }

  const surveyContent = (
    <div style={{ flex: 1, height: '100%' }}>
      {screen === 'welcome' ? (
        <WelcomeSurveyScreen onStartCallback={() => setScreen('dialog')} />
      ) : (
        <SurveyDetailScreen
          onFinishCallback={() => {
            setScreen('finished');
            LocalStorage.setSurveyDismiss(true);
          }}
          onCloseCallback={() => {
            setShowModal(false);
            setShowWidget(false);
          }}
          questionIndex={questionIndex}
          setQuestionIndex={setQuestionIndex}
          surveyResult={surveyResult}
          setSurveyResult={setSurveyResult}
          progress={progress}
          setProgress={setProgress}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      )}
    </div>
  );

  if (surveyIsAnswered) {
    return null;
  }

  return (
    <Styles.SurveyWrapper>
      {!surveyIsAnswered && surveyWidget}
      <PopupScreenCenter
        visible={showModal}
        overlayAlpha={0.7}
        width={700}
        showCloseButton={true}
        onOverlayClick={() => setShowModal(false)}
        confirmDialog={surveyConfirmDialog()}
        content={surveyContent}
      />
    </Styles.SurveyWrapper>
  );
};
