import * as React from 'react';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { PopupDialog } from '../../components/popup/PopupDialog';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

const Title = styled.div``;

const LogoutBody1 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.border};
  margin-top: 24px;
`;

const LogoutBody2 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmLogoutDialog = ({ onConfirm, onCancel }: Props) => {
  const title = (
    <>
      <Title>
        <I18N text={'confirm_logout_title'} />
      </Title>
    </>
  );

  return (
    <PopupDialog
      title={title}
      confirmAction={{ onClick: onConfirm, variant: 'danger', text: i18N('confirm_logout_yes'), style: { textTransform: 'none' } }}
      cancelAction={{ onClick: onCancel, text: i18N('confirm_logout_no'), style: { textTransform: 'none' } }}
      onDismiss={onCancel}
      content={
        <>
          <LogoutBody1>
            <I18N text={'confirm_logout_body1'} />
          </LogoutBody1>
          <LogoutBody2>
            <I18N text={'confirm_logout_body2'} />
          </LogoutBody2>
          <Divider />
        </>
      }
    />
  );
};
