import { PopupDialog } from '../../components/popup/PopupDialog';
import { i18N } from '../../internationalization/AppText';
import { useLanguage } from '../../common/selectors';
import { tracker } from '@visikon/tracker/src';
import { useHistory } from 'react-router-dom';
import { useBackbone } from '@visikon/backbone';

const SelfServicePopup = () => {
  const selfServiceUrl = useBackbone((state) => state.hosts.selfService);
  const lang = useLanguage();
  const history = useHistory();

  const handleConfirm = () => {
    window.location.href = `${selfServiceUrl}${lang}/login`;
  };

  const popupBodyJsx = <p style={{ fontSize: '20px', whiteSpace: 'pre-wrap' }}>{i18N('self_service_redirect')}</p>;

  return (
    <PopupDialog
      slideDirection={'up'}
      title={i18N('self_service_redirect_title')}
      confirmAction={{ onClick: handleConfirm, text: i18N('continue') }}
      cancelAction={{
        onClick: () => {
          tracker.trackEvent('Auth', 'logout');
          history.push('/logout');
        },
        text: i18N('log_out'),
      }}
      content={popupBodyJsx}
    />
  );
};

export default SelfServicePopup;
