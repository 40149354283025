import { App } from './App';
import { createRoot } from 'react-dom/client';
import { backbone } from '@visikon/backbone/src';
import '@visikon/utils/src/NetworkMonitor';

const { version } = require('../package.json');

backbone.update({
  client: {
    type: 'web',
    version,
  },
});

function main() {
  createRoot(document.getElementById('root') as HTMLElement).render(<App />);
}

// TODO: Enforce https in frontdoor
main();
