import styled from 'styled-components';

type AlignmentValues = 'center' | 'flex-end' | 'flex-start' | 'stretch' | 'inherit' | 'initial';
export const DialogBackground = styled.div<{ alignVertical?: AlignmentValues }>`
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.alignVertical ?? 'center'};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  width: 100vw;
  height: 100%;
  min-height: 100vh;

  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);

  box-sizing: border-box;
  padding: 25px;
  flex-direction: column;
`;
