import React from 'react';
import { classes } from 'typestyle';
import { buttonStyles } from './Button.styles';

export type VariantType = 'default' | 'danger' | 'outline' | 'text';
interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: VariantType;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export function Button(props: Props) {
  const { variant, size, className, ...rest } = props;

  const setSyles = buttonStyles({
    variant: variant || 'default',
    size,
  });

  return (
    <button type="button" className={classes(setSyles, className)} {...rest}>
      {props.children}
    </button>
  );
}
