import styled from 'styled-components';
import { DialogBackground } from '@visikon/mytreatment/src/components/styled/Overlay';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

export const ModalWrapper = styled(DialogBackground)`
  display: flex;
  justify-content: center;
  padding: unset !important;
  overflow: hidden;
  dialog {
    background: ${color.homeBackground};
    position: relative;
    margin: 0 1rem;
    max-width: 36rem;
  }
`;
