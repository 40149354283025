import { usePromise } from '../../common/utils';
import { ProgramsStorage, StoredPrograms } from '../../local-storage/programs-storage';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import * as React from 'react';
import { listAvailablePrograms, ProgramInfo } from '../../common/listPrograms';
import { useEffect } from 'react';
import { Country, getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { backbone } from '@visikon/backbone';
import { archiveProgram } from '../../actions/userActions';

export const useProgramList = () => {
  const dispatch = useDispatch();
  const country = backbone.store.getState().country;
  const programs = usePromise<StoredPrograms | undefined>(ProgramsStorage.get);
  const programsData = useSelector((state: IState) => state.userData.programsData);
  const [programList, setProgramList] = React.useState<ProgramInfo[]>([]);
  const [countryCodesSupported, setCountryCodesSupported] = React.useState<Country[]>([]);

  useEffect(() => {
    const programList = listAvailablePrograms(programs?.availablePrograms, country.languageCode, programsData);
    programList.sort((a, b) => (!a.order || !b.order ? 0 : a.order - b.order));
    setProgramList(programList);
  }, [programs, programsData, country.languageCode]);

  useEffect(() => {
    const countryCodesSupported = programList.reduce<Country[]>((accu, programInfo) => {
      programInfo.languages.forEach((languageCode) => {
        if (!accu.find((c) => c.languageCode === languageCode)) accu.push(getCountryByLanguageCode(languageCode));
      });
      return accu;
    }, []);
    setCountryCodesSupported(countryCodesSupported);
  }, [programList]);

  const archive = (programId: string) => {
    dispatch(archiveProgram(programId));
  };

  return { programList, countryCodesSupported, archive };
};
