import { useState } from 'react';
import Cross from '../../images/cross_primary.svg';
import { MobileUtils, usePromise } from '../../common/utils';
import AppLogo from '../../images/svg/logo/app_logo.png';
import { I18N } from '../../internationalization/AppText';
import { useWindowSizeMatches, WindowSize } from '@visikon/utils/src/responsive';
import { MitforlobContent, useIsEmbedded } from '../../reducers/resourcesReducer';
import { useIsVideoPlayerOpen } from '../videoPlayer/PlayerContainer';
import { LOCAL_STORAGE_MOBILE_DIALOG_DISMISSED_KEY } from '../../common/config';
import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK, GOOGLE_PLAY_STORE_LINK_ANDROID } from '@visikon/core-models/misc';
import { ProgramInfo, useProgramInformationFromContent } from '../../common/selectors';
import { tracker } from '@visikon/tracker/src';
import { BannerHeader, BannerText, BannerWrapper, Container, DismissCross, GetButton, Header, LogoWrapper, Outer } from './SmartBanner.styles';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { GetStorage } from '../../local-storage/Storage';

function useShowBanner(): boolean {
  const native = MobileUtils.isNative();
  const content = useSelector<IState, MitforlobContent | undefined>((state) => state.resources.content);
  const programInfo = usePromise<ProgramInfo>(useProgramInformationFromContent, content);
  const directAccess = programInfo?.enableDirectAccess;
  const isEmbedded = useIsEmbedded();
  const isVideoPlayer = useIsVideoPlayerOpen();
  return MobileUtils.isMobile() && !native && !isEmbedded && !isVideoPlayer && directAccess !== true;
}

export const SmartBanner = () => {
  const [dismissed, setDismissed] = useState(false);
  const isMobileView = useWindowSizeMatches(WindowSize.Small);
  const dismissValueFromStorage = usePromise<string | null>(GetStorage().getItem, LOCAL_STORAGE_MOBILE_DIALOG_DISMISSED_KEY);

  const gotoStoreLink = () => {
    if (MobileUtils.isAndroid()) {
      try {
        window.open(GOOGLE_PLAY_STORE_LINK_ANDROID);
      } catch (e) {
        window.open(GOOGLE_PLAY_STORE_LINK);
      }
    } else {
      window.open(APPLE_APP_STORE_LINK);
    }
    tracker.trackEvent('Auth', `appStoreRedirect`, MobileUtils.isAndroid() ? 'Google' : 'Apple');
  };
  const dismiss = async () => {
    setDismissed(true);
    await GetStorage().setItem(LOCAL_STORAGE_MOBILE_DIALOG_DISMISSED_KEY, new Date().toISOString());
    tracker.trackEvent('UserData', 'SmartBanner', 'dismiss');
  };

  const getButton = (
    <GetButton onClick={gotoStoreLink}>
      <I18N text="smart_banner_get" />
    </GetButton>
  );

  const show = useShowBanner() && dismissValueFromStorage === null && !dismissed;
  if (show) {
    tracker.trackScreen('SmartBanner');
  }

  return (
    <>
      {show && (
        <Outer>
          <Container>
            <BannerWrapper>
              <LogoWrapper>
                <img src={AppLogo} alt={'logo'} width={38} />
              </LogoWrapper>
              <Header>
                <BannerHeader>
                  <I18N text="smart_banner_header" />
                </BannerHeader>
                <BannerText>
                  <I18N text={'smart_banner_description'} />
                </BannerText>
              </Header>
              {!isMobileView && getButton}
              <DismissCross onClick={dismiss} tabIndex={-1}>
                <img alt="Dismiss" src={Cross} tabIndex={0} />
              </DismissCross>
            </BannerWrapper>
            {isMobileView && getButton}
          </Container>
        </Outer>
      )}
    </>
  );
};
