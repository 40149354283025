import * as React from 'react';
import { Fader } from '../../common/Fader';
import { Button, Dialog, VariantType } from '@visikon/spine';
import { I18N, i18N } from '../../internationalization/AppText';
import { ModalWrapper } from '@visikon/nativeui';
import { SlideDirection, Slider } from '../../common/Slider';
import { SvgIcon } from '../../images/svgIcon';
import { ReactComponent as CrossIcon } from '../../images/svg/Cross.svg';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import color = Tokens.color;
import { ReactNode, CSSProperties } from 'react';
import FocusTrap from 'focus-trap-react';
import { createPortal } from 'react-dom';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${color.primary};
  text-align: left;
`;

type ActionSpecs = {
  text?: string;
  variant?: VariantType;
  style?: CSSProperties;
  onClick: () => void;
};
export type Props = {
  title: string | ReactNode;
  onDismiss?: () => void;
  cancelAction?: ActionSpecs;
  confirmAction?: ActionSpecs;
  content: ReactNode;
  fadeIn?: number;
  slideDirection?: SlideDirection;
};

export const PopupDialog = (props: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { confirmAction, cancelAction, onDismiss, content, title, fadeIn, slideDirection } = props;

  const overLayClickHandler = (ev: React.MouseEvent) => {
    // Only handle if wrapper elements was pressed, else we catch click to child elements
    if (ev.target !== wrapperRef.current) return;

    onDismiss && dismissDialog();
  };

  const dismissDialog = () => {
    onDismiss?.();
  };

  const onConfirmHandler = () => {
    if (confirmAction && confirmAction.onClick) {
      confirmAction.onClick();
    }
  };

  const onCancelHandler = () => {
    cancelAction && cancelAction.onClick();
  };

  const actions: JSX.Element[] = [];

  if (confirmAction) {
    actions.push(
      <Button key="confirmKey" size="small" onClick={onConfirmHandler} style={confirmAction.style} variant={confirmAction.variant}>
        {confirmAction.text ? confirmAction.text : <I18N text={'accept'} />}
      </Button>,
    );
  }

  if (cancelAction) {
    actions.push(
      <Button key="cancelKey" variant={'text'} size="small" onClick={onCancelHandler} style={cancelAction.style}>
        {cancelAction.text ? cancelAction.text : <I18N text={'cancel'} />}
      </Button>,
    );
  }

  const titleComponent = () => {
    const titleElement = <HeaderTitle>{title}</HeaderTitle>;
    return onDismiss ? (
      <Header>
        {titleElement}
        <SvgIcon
          as={'button'}
          Icon={CrossIcon}
          width={12}
          style={{ padding: 8, height: '100%', cursor: 'pointer' }}
          onClick={onDismiss}
          aria-label={i18N('close')}
        />
      </Header>
    ) : (
      titleElement
    );
  };

  const rawDialog = (
    <Dialog open={true} header={titleComponent()} actions={actions.length > 0 ? <>{actions}</> : null}>
      {content}
    </Dialog>
  );

  const fadeInDialog = fadeIn ? <Fader timeout={fadeIn}>{rawDialog}</Fader> : rawDialog;

  const finalDialog = slideDirection ? <Slider slideDirection={slideDirection}>{fadeInDialog}</Slider> : fadeInDialog;

  return createPortal(
    <FocusTrap focusTrapOptions={{ initialFocus: wrapperRef.current as HTMLElement }}>
      <div>
        <ModalWrapper ref={wrapperRef} onClick={overLayClickHandler}>
          {finalDialog}
        </ModalWrapper>
      </div>
    </FocusTrap>,
    document.body,
  );
};
