import { ContactDialog } from './ContactDialog';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@visikon/spine';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { I18N } from '../../internationalization/AppText';
import { IState } from '../../reducers/reducer';
export const ContactButton = () => {
  const contactText = useSelector((state: IState) => state.resources.content?.contactText);
  const [showContact, setShowContact] = React.useState(false);

  return contactText ? (
    <>
      <div style={{ width: '500' }}>
        <Button
          onClick={() => setShowContact(true)}
          size={'small'}
          variant={'text'}
          style={{
            textTransform: 'none',
            background: 'rgba(0, 109, 179, 0.06)',
            fontWeight: Tokens.text.weight.bold,
            padding: '8px 12px',
          }}
        >
          <I18N text={'contact'} />
        </Button>
      </div>
      {showContact && <ContactDialog document={contactText} onCancel={() => setShowContact(false)} />}
    </>
  ) : null;
};
