import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserContext } from '../../actions/authActions';
import { useHasContent } from '../../reducers/resourcesReducer';
import { Spinner } from '../../common/Spinner';
import styled from 'styled-components';
import { UserContext } from '../../reducers/authReducer';
import { IState } from '../../reducers/reducer';
import { ErrorMessage } from './LoginContainer.styles';
import { ProgramsStorage } from '../../local-storage/programs-storage';

export const SET_USER_ROUTE = '/set-user';
export const SET_USER_PATH = `${SET_USER_ROUTE}/:token/:program/:language?`;

export function useContextFromParams() {
  const { token, program, language } = useParams<{ token: string; program: string; language: string }>();
  return { token, programId: program, languageCode: language || 'da' } as UserContext;
}

const CenterDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

// Takes a program id AND a token from auth, and changes the program
// redirects to the first content in that program

export function SetUserRouter() {
  const userContext = useContextFromParams();
  const dispatch = useDispatch();
  const hasContent = useHasContent();
  const history = useHistory();
  const error = useSelector((state: IState) => state.resources.error);

  useEffect(() => {
    if (!userContext.token || !userContext.programId) {
      console.log('Missing params', userContext);
      history.push('/');
    }

    void ProgramsStorage.setActiveProgram(userContext.programId);
    dispatch(setUserContext(userContext));
  }, []);

  // Wait for content loaded
  useEffect(() => {
    if (hasContent) {
      history.push(`/${userContext.programId}/video`);
    }
  }, [hasContent]);

  return <CenterDiv>{error ? <ErrorMessage>{error}</ErrorMessage> : <Spinner />}</CenterDiv>;
}
