import * as React from 'react';
import * as Styles from '../components/styled/DetailsCard';
import { LazyImage } from '@visikon/utils';
export type Alignment = 'left' | 'right' | 'none';

type Props = { thumbUrl?: string; aligned?: Alignment } & React.HTMLProps<HTMLImageElement>;
function Thumbnail({ thumbUrl, aligned, ...rest }: Props) {
  const alignment = aligned || Thumbnail.defaultProps.aligned;

  return (
    <Styles.Thumbnail aligned={alignment}>
      <LazyImage imageUrl={thumbUrl} {...rest} />
    </Styles.Thumbnail>
  );
}

Thumbnail.defaultProps = {
  aligned: 'left' as 'right' | 'left',
};

export default Thumbnail;
