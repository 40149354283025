import { HeaderIcon } from './styled/HeaderIcon';
import { i18N } from '../internationalization/AppText';
import { useHistory } from 'react-router-dom';
import { SvgIcon } from '../images/svgIcon';
import { ReactComponent as Logout } from '../images/svg/logout.svg';
import styled from 'styled-components';
import { useState } from 'react';
import { ConfirmLogoutDialog } from '../screens/header/ConfirmLogoutDialog';
import { tracker } from '@visikon/tracker/src';
import { Unstyled } from './Unstyled';

export const LogoutHeaderIcon = styled(HeaderIcon)`
  margin-right: 0;
  flex-direction: row;
  align-items: center;
  @media all and (max-width: 300px) {
    margin-left: 2px;
  }
`;

export function LogoutIcon() {
  const [clicked, setClicked] = useState(false);
  const history = useHistory();
  return (
    <>
      <Unstyled.Button onClick={() => setClicked(true)} title={i18N('log_out')}>
        <LogoutHeaderIcon aria-hidden="true">
          <SvgIcon Icon={Logout} width={24} aria-hidden />
        </LogoutHeaderIcon>
      </Unstyled.Button>
      {clicked && (
        <ConfirmLogoutDialog
          onConfirm={() => {
            tracker.trackEvent('Auth', 'logout');
            history.push('/logout');
          }}
          onCancel={() => {
            setClicked(false);
          }}
        />
      )}
    </>
  );
}
